
import {
  Component, Prop, Ref, Vue, Watch,
} from 'vue-property-decorator';
import axios from 'axios';
import { stringFormat } from '@/utils/index.js';
import { Form } from '../types';
import { userFormsUrl, formsApiEndpoint, formsUrl } from '@/utils/url-manager.js';

@Component
export default class PageShare extends Vue {
  @Prop({ required: false, default: '' })
  private readonly pageId: string;

  @Ref()
  private readonly linkRef: HTMLInputElement;

  private copied = false;

  private selectedFormId: null | string = null;

  private forms: Form[] | null = null;

  private formBaseUrl = formsUrl;

  get link() {
    return this.selectedFormId
      ? `${this.formBaseUrl}${this.selectedFormId}?layout=${this.pageId}`
      : null;
  }

  @Watch('selectedFormId')
  private onFormSelect(value: string) {
    window.localStorage.setItem(`share_page_${this.pageId}`, value);
  }

  @Watch('pageId', { immediate: true })
  private async onPageChange() {
    if (!this.forms) {
      this.forms = (
        await axios.get<Form[]>(`${formsApiEndpoint}v2/designer/forms/active`)
      ).data;

      const storedFormId = window.localStorage.getItem(`share_page_${this.pageId}`);

      if (this.forms?.length > 0) {
        this.selectedFormId = this.forms.some((form) => form.id === storedFormId)
          ? storedFormId
          : this.forms[0].id;
      }

      const userDomain = (await axios.get(`${formsApiEndpoint}v2/user/domain`))
        .data;

      if (userDomain) {
        this.formBaseUrl = stringFormat(userFormsUrl, userDomain);
      }
    }
  }

  public mounted() {
    this.$root.$el.append(this.$el);
  }

  private copyLink() {
    this.linkRef.select();
    document.execCommand('copy');
    this.copied = true;

    setTimeout(() => (this.copied = false), 3000);
  }

  private close() {
    this.$emit('close');
  }
}
